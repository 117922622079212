import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Overlay from 'ol/Overlay';
import { toLonLat } from 'ol/proj';

function Popup({ type, edit, isMap2 = false, isComparison = false }) {
  
  const popupDiv = useRef();
  const mapObj = useSelector((state) => isComparison ? isMap2 ? state.gis.icMap2 : state.gis.icMap1 : state.gis.map);
  const [lonLat, setLonLat] = useState();
  const [popupContent, setPopupContent] = useState();
  const [features, setFeatures] = useState();
  const [cropId, setCropId] = useState();

  useEffect(() => {
    if (!mapObj) return;

    const overlay = new Overlay({
      element: popupDiv.current,
      autoPan: {
        animation: {
          duration: 250,
        },
      },
    });
    mapObj.addOverlay(overlay);

    const clickFn = (evt) => {
      
      const coordinate = evt.coordinate;
      setLonLat(toLonLat(coordinate));
      if (type === 'wfs') {
        if (
          mapObj.hasFeatureAtPixel(evt.pixel, {
            layerFilter: (layer) => layer.get('name') === 'farmLayer',
          })
        ) {
          mapObj.forEachFeatureAtPixel(
            evt.pixel,
            (feature, layer) => {
              setFeatures(feature.getProperties());
              setCropId(feature.getId());
              overlay.setPosition(coordinate);
            },
            {
              layerFilter: (layer) => layer.get('name') === 'farmLayer',
            }
          );
        } else {
          overlay.setPosition(undefined);
        }
      }
      if (type === 'wms') {
        const theLayer = mapObj
          .getLayers()
          .getArray()
          .find(function (layer) {
            return layer.get('name') === 'farmWms';
          });
        if (theLayer) {
          const viewResolution = /** @type {number} */ (
            mapObj.getView().getResolution()
          );
          const url = theLayer
            .getSource()
            .getFeatureInfoUrl(evt.coordinate, viewResolution, 'EPSG:3857', {
              INFO_FORMAT: 'application/json',
            });
          if (url) {
            fetch(url)
              .then((response) => response.json())
              .then((html) => {
                if (html.features.length > 0) {
                  setPopupContent(html.features[0]);
                  overlay.setPosition(coordinate);
                } else {
                  overlay.setPosition(undefined);
                  setPopupContent(undefined);
                }
              });
          }
        }
      }
    };

    if (edit) {
      mapObj.un('click', clickFn);
    } else {
      mapObj.on('click', clickFn);
    }

    return () => {
      // Cleanup: remove the overlay when the component unmounts or when edit changes
      mapObj.un('click', clickFn);
      mapObj.removeOverlay(overlay);
    };
  }, [mapObj, edit]);

  return (
    <div ref={popupDiv} className='bg-ext-green-light p-2 rounded-lg shadow-md'>
      {type === 'wms' && popupContent && (
        <p>Gray Index: {popupContent.properties.GRAY_INDEX}</p>
      )}
      {type === 'wfs' && features && (
        <ul>
          <li>Crop ID: {cropId}</li>
          <li>Crop: {features.crop}</li>
          <li>Sowing Date: {features.sowingdate}</li>
          <li>Area: {(features.area / 4046).toFixed(2)}</li>
        </ul>
      )}
    </div>
  );
}

export default Popup;
