import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppHeader from "../components/AppHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import Map1Component from "../components/ImageComparison/Map1Component";
import Map2Component from "../components/ImageComparison/Map2Component";
import { ToastContainer } from "react-toastify";
import Synchronize from "ol-ext/interaction/Synchronize";
import 'ol-ext/dist/ol-ext.css';

function ImageComparison() {
  const params = useParams();
  const navigate = useNavigate();
  const { isLogedIn } = useSelector((state) => state.global);
  const map1 = useSelector((state) => state.gis.icMap1);
  const map2 = useSelector((state) => state.gis.icMap2);

  useEffect(() => {
    if (!isLogedIn) {
      navigate("/login?back=" + window.location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogedIn]);

  useEffect(() => {
    if(!map1 || !map2) return;
    var synchronize_12 = new Synchronize({ maps: [map2] });
    var synchronize_21 = new Synchronize({ maps: [map1] });
    map1.addInteraction( synchronize_12 );
    map2.addInteraction( synchronize_21 );

    return () => {
      // if(map1) map1.removeInteraction(synchronize_12);
      // if(map2) map2.removeInteraction(synchronize_21);
    }
  }, [map1, map2])
  

  return (
    <div className="h-screen flex flex-col">
      <Link
        to={"/farms/" + params.farmId}
        className="absolute top-3 right-2  justify-center items-center"
      >
        <FontAwesomeIcon
          className="p-2 text-3xl text-lime-300"
          icon={faCircleArrowLeft}
        />
      </Link>
      <div className="basis-1/12 bg-ext-dark text-ext-green-light">
        <AppHeader />
      </div>
      <ToastContainer position="top-center" closeOnClick pauseOnHover autoClose={2500} />
      <div className="flex flex-col lg:flex-row basis-11/12">
        <Map1Component params={params}/>       
        <Map2Component params={params}/>      
      </div>
    </div>
  );
}

export default ImageComparison;
