import { configureStore } from '@reduxjs/toolkit'
import globalReucer from './global'
import gisReucer from './gis'

export const store = configureStore({
  reducer: {
    global: globalReucer,
    gis: gisReucer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
})