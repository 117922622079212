import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gisAction } from "../../../../store/gis";
// import MapContext from "./MapContext";
import { fromLonLat } from "ol/proj";
import proj4 from "proj4";
import { register } from "ol/proj/proj4";
import { transform } from "ol/proj";

import { Point } from "ol/geom";
import * as ol from "ol";
import "./Map.css";
import "../../../../../node_modules/ol/ol.css";

const MyMap = ({ children, zoom, center, trackLocation }) => {
  const dispatch = useDispatch();
  // const map = useSelector((state) => state.gis.map);
  const [map, setMap] = useState();
  const mapRef = useRef();
  // proj4.defs("EPSG:3857", "+proj=utm +zone=43 +datum=WGS84 +units=m +no_defs");
  // register(proj4);
  React.useEffect(() => {
    var op = {
      view: new ol.View({
        projection: "EPSG:3857",
        zoom,
        center: center,
      }),
      layer: [],
      layers: [],
      controls: [],
      overlays: [],
    };
    let mapObj = new ol.Map(op);
    mapObj.setTarget(mapRef.current);
    dispatch(gisAction.updateMap(mapObj));
    

    if (trackLocation && center === null) {
      const geolocation = new ol.Geolocation({
        trackingOptions: {
          enableHighAccuracy: true,
        },
        projection: mapObj.getView().getProjection(),
      });
      navigator.geolocation.watchPosition(
        function (pos) {
          const coords = [pos.coords.longitude, pos.coords.latitude];
          var geom = new Point(transform(coords, "EPSG:4326", "EPSG:3857"));
          mapObj.getView().fit(geom.getExtent(), {
            maxZoom: 18,
            duration: 500,
          });
        },
        function (error) {
          alert(`ERROR: ${error.message}`);
        },
        {
          enableHighAccuracy: true,
        }
      );
    }

    setMap(mapObj);
    mapObj.on("click", (evt) => {
      // console.log(evt.coordinate);
    });
    return () => mapObj.setTarget(undefined);
  }, []);

  useEffect(() => {
    if (!map) return;
    map.getView().setCenter(transform(center, "EPSG:4326", "EPSG:3857"));
  }, [center]);

  useEffect(() => {
    if (!map) return;
    map.getView().setZoom(zoom);
  }, [zoom]);

  return (
    // <MapContext.Provider value={{ map }}>
    <div ref={mapRef} className="map">
      {children}
    </div>
    // </MapContext.Provider>
  );
};
export default MyMap;
