import React, { Fragment, useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import AppHeader from '../components/AppHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarDays,
  faRectangleXmark,
  faEllipsisVertical,
  faCaretDown,
  faCaretUp,
} from '@fortawesome/free-solid-svg-icons';
import DataOnDate from '../components/farmPage/DataOnDate';
import DataChart from '../components/farmPage/DataChart';
import AppLoader from '../components/ui/AppLoader';
import AdviceModal from '../components/farmPage/AdviceModal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useSelector } from 'react-redux';
import GeoJSON from 'ol/format/GeoJSON';
import MyMap from '../components/Mapping/components/Map/MyMap';
// import Map from "../components/Mapping/components/Map";
import {
  MyLayers,
  VectorLayer,
  TileLayer,
} from '../components/Mapping/components/Layers';
import { fromLonLat, get } from 'ol/proj';
import { osm, vector, xyz, wms } from '../components/Mapping/components/Source';
import Popup from '../components/Mapping/components/Popup/Popup';
import imgNorth from '../images/north.png';
import eviImg from '../images/evi.jpeg';
import ndviImg from '../images/ndvi.jpeg';
import ndmiImg from '../images/ndmi.jpeg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import authHeader from '../services/auth-header';
import { jsPDF } from 'jspdf'; // to generate report in pdf format
import 'jspdf-autotable';
import logo from '../images/logo1.png';
import north from '../images/north.jpg';
import ndmiLegend from '../images/ndmi.jpeg';
import ndviLegend from '../images/ndvi.jpeg';
import eviLegend from '../images/evi.jpeg';
import { Modify, Select } from 'ol/interaction';
import OLVectorSource from 'ol/source/Vector';
import OLVectorLayer from 'ol/layer/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import WKT from 'ol/format/WKT';

function FarmPage() {
  const { rootApiUrl, isLogedIn } = useSelector((state) => state.global);
  const navigate = useNavigate();

  const params = useParams();
  const map = useSelector((state) => state.gis.map);

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [farmData, setFarmData] = useState(null);
  const [farmDataOL, setFarmDataOL] = useState(null);
  const [values, setValues] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [selectedValue, setSelectedValue] = useState(0);
  const [measure, setMeasure] = useState('ndvi');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [dateExclude, setDateExclude] = useState([]);
  const [advisory, setAdvisory] = useState();
  const [showLegend, setShowLegend] = useState(false);
  const [center, setCenter] = useState([78.9629, 20.5937]);
  const [isEditing, setIsEditing] = useState(false);
  const [selectInteraction, setSelectInteraction] = useState(null);
  const [modifyInteraction, setModifyInteraction] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const today = new Date();

  useEffect(() => {
    if (!isLogedIn) {
      navigate('/login?back=' + window.location.pathname);
    }
  }, [isLogedIn]);

  useEffect(() => {
    if (!isLogedIn) return;
    async function getFarmData() {
      setLoading(true);

      const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        redirect: 'follow',
      };

      await fetch(rootApiUrl + 'onefarm/' + params.farmId + '/', requestOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            navigate('/');
          }
        })
        .then((data) => {
          setFarmData(data);
          setCenter([data.properties.lon, data.properties.lat]);
          setFarmDataOL(
            new GeoJSON().readFeature(data, {
              featureProjection: get('EPSG:3857'),
            })
          );
          if (!data.geometry) {
            navigate('/add-farm/' + data.id);
          }
        });

      setLoading(false);
    }

    getFarmData();
    getValues(measure);
  }, []);

  async function getAdvisory(index) {
    setLoading(true);

    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
    };
    let url =
      rootApiUrl +
      'advisory/' +
      index +
      '/' +
      farmData?.properties.crop +
      '/' +
      farmData?.properties.sowingdate +
      '/' +
      values[selectedValue]?.value;
    await fetch(url, requestOptions)
      .then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            if (data.error) {
              toast.warning(data.error);
            } else {
              setAdvisory(data);
              setShowModal(true);
            }
          });
        } else {
          res.json().then((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  }

  function changeMeasureOpt(event) {
    setMeasure(event.target.value);
    getValues(event.target.value);
    getAdvisory(measure);
    setStartDate(new Date());
    setEndDate(null);
  }

  async function getValues(opt) {
    setLoading(true);

    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
    };

    await fetch(rootApiUrl + opt + '/?farmid=' + params.farmId, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const filteredDate = data.filter((x) => x.value);
        setValues(filteredDate);
        let chartData = [];
        filteredDate.map((date) => {
          return date.value && chartData.push(date);
        });
        setChartData(chartData);
        let excludeDates = [];
        filteredDate.forEach((x) => {
          if (x.value == null) excludeDates.push(Date.parse(x.date));
        });
        setDateExclude(excludeDates);
      });
    setLoading(false);
  }

  function filterByDate() {
    const filteredDate = chartData.filter((dateRange) => {
      return (
        new Date(dateRange.date).getTime() >= new Date(startDate).getTime() &&
        new Date(dateRange.date).getTime() <= new Date(endDate).getTime()
      );
    });
    setChartData(filteredDate);
  }

  let layerName =
    values.length > 0 &&
    'kb:' + measure + '-' + values[selectedValue]?.date + '-' + params.farmId;

  // Function : Get the current measure's legend
  function getLegend() {
    if (measure === 'ndmi') {
      return ndmiLegend;
    } else if (measure === 'ndvi') {
      return ndviLegend;
    } else {
      return eviLegend;
    }
  }

  // Function : Extract Map from HTML and convert it to an Image
  function exportPdf(selectedAdvices) {
    const resolution = 72;
    const dim = [420, 297];
    const width = Math.round((dim[0] * resolution) / 25.4);
    const height = Math.round((dim[1] * resolution) / 25.4);
    const size = map.getSize();
    const viewResolution = map.getView().getResolution();

    map.once('rendercomplete', function () {
      const mapCanvas = document.createElement('canvas');
      mapCanvas.width = width;
      mapCanvas.height = height;
      const mapContext = mapCanvas.getContext('2d');
      Array.prototype.forEach.call(
        map.getViewport().querySelectorAll('.ol-layer canvas, canvas.ol-layer'),
        function (canvas) {
          if (canvas.width > 0) {
            const opacity =
              canvas.parentNode.style.opacity || canvas.style.opacity;
            mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
            let matrix;
            const transform = canvas.style.transform;
            if (transform) {
              // Get the transform parameters from the style's transform matrix
              matrix = transform
                .match(/^matrix\(([^\(]*)\)$/)[1]
                .split(',')
                .map(Number);
            } else {
              matrix = [
                parseFloat(canvas.style.width) / canvas.width,
                0,
                0,
                parseFloat(canvas.style.height) / canvas.height,
                0,
                0,
              ];
            }
            // Apply the transform to the export map context
            CanvasRenderingContext2D.prototype.setTransform.apply(
              mapContext,
              matrix
            );
            const backgroundColor = canvas.parentNode.style.backgroundColor;
            if (backgroundColor) {
              mapContext.fillStyle = backgroundColor;
              mapContext.fillRect(0, 0, canvas.width, canvas.height);
            }
            mapContext.drawImage(canvas, 0, 0);
          }
        }
      );
      mapContext.globalAlpha = 1;
      mapContext.setTransform(1, 0, 0, 1, 0, 0);
      let mapImage = mapCanvas.toDataURL('image/jpeg');
      generatePDF(selectedAdvices, mapImage);
    });

    // Set print size
    const printSize = [width, height];
    map.setSize(printSize);
    const scaling = Math.min(width / size[0], height / size[1]);
    map.getView().setResolution(viewResolution / scaling);
  }

  // Funtion : Calculate width
  function calculateWidth(doc, string, fontSize) {
    let value =
      (doc.getStringUnitWidth(string).toFixed(0) * fontSize) / (72 / 25.6);
    return value;
  }

  // Function : Generating PDF to create report
  function generatePDF(selectedAdvices, mapImage) {
    const doc = new jsPDF({ compress: true, lineHeight: 15 });

    let date = new Date().toISOString().split('T')[0];
    let legend = getLegend();

    doc.setFont('helvetica', 'normal');
    doc.setFontSize(14);
    doc.text(`Current Date: ${date}`, 195, 15, null, null, 'right');
    doc.addImage(logo, 'JPEG', 10, 5, 45, 20);
    doc.line(10, 30, 200, 30);
    doc.setFontSize(18);
    doc.setFont('helvetica', 'bold');
    doc.text('Farm Details: ', 10, 45);
    doc.setFontSize(14);
    doc.text(`Crop: `, 15, 55);
    doc.text(`Farm Id: `, 125, 55);
    doc.text(`Total Area: `, 15, 65);
    doc.text(`Sowing Date: `, 125, 65);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `${
        farmData?.properties.crop.charAt(0) +
        farmData?.properties.crop.slice(1).toLowerCase()
      }`,
      calculateWidth(doc, 'Total Area: ', 14) + 20,
      55
    );
    doc.text(
      `${(farmData?.properties.area / 4046.85642).toFixed(3)} Acers`,
      calculateWidth(doc, 'Total Area: ', 14) + 20,
      65
    );
    doc.text(
      `${params.farmId}`,
      calculateWidth(doc, 'Sowing Date: ', 14) + 130,
      55
    );
    doc.text(
      `${farmData?.properties.sowingdate}`,
      calculateWidth(doc, 'Sowing Date: ', 14) + 130,
      65
    );
    doc.setFontSize(18);
    doc.setFont('helvetica', 'bold');
    doc.text(`${measure.toUpperCase()} Report: `, 10, 80);
    doc.setFontSize(14);
    doc.setFont('helvetica', 'normal');
    doc.text(`Index Date: ${values[selectedValue]?.date}`, 140, 80);
    doc.addImage(mapImage, 'JPEG', 10, 90, 130, 100);
    doc.addImage(north, 'JPEG', 128, 92, 10, 10);
    doc.addImage(legend, 'JPEG', 150, 90, 50, 100);
    doc.setFontSize(16);
    doc.text(
      `Mean Value of ${measure.toUpperCase()}: ${values[
        selectedValue
      ]?.value.toFixed(2)}`,
      10,
      200
    );
    doc.setFontSize(18);
    doc.setFont('helvetica', 'bold');
    doc.text(`Advisory: `, 10, 210);
    doc.setFontSize(14);
    if (Object.keys(selectedAdvices).length) {
      let y = 230;
      for (let key in selectedAdvices) {
        if (selectedAdvices.hasOwnProperty(key)) {
          let advice = selectedAdvices[key];
          if (y >= 280) {
            doc.addPage();
            y = 10;
          }
          if (advice.length > 60) {
            let lines = doc.splitTextToSize(advice, 110);
            doc.setFont('helvetica', 'bold');
            doc.text(key.charAt(0).toUpperCase() + key.slice(1) + ': ', 15, y);
            doc.setFont('helvetica', 'normal');
            doc.text(
              lines[0],
              25 + calculateWidth(doc, 'Pest & Disease Management: ', 14),
              y
            );
            for (let i = 1; i < lines.length; i++) {
              if (y >= 280) {
                doc.addPage();
                y = 10;
              }
              y += 7;
              doc.text(
                lines[i],
                25 + calculateWidth(doc, 'Pest & Disease Management: ', 14),
                y
              );
            }
          } else {
            doc.setFont('helvetica', 'bold');
            doc.text(key.charAt(0).toUpperCase() + key.slice(1) + ': ', 15, y);
            doc.setFont('helvetica', 'normal');
            doc.text(
              advice,
              25 + calculateWidth(doc, 'Pest & Disease Management: ', 14),
              y
            );
          }
        }
        y += 12;
      }
    }
    doc.save(`${params.farmId}-${measure.toUpperCase()}-${date}.pdf`);
    toast.success('Report generated Successfully !');
  }

  // Function : Get Map Layer depending on the name sent
  function getMapLayer(name) {
    return map
      .getLayers()
      .getArray()
      .filter((layer) => layer.get('name') === name)[0];
  }

  // Function : Handle Edit Map Click
  function startEditingMap() {
    console.log('Editing...');
    setIsEditing(true);
    const mainVectorLayer = getMapLayer('farmLayer');
    const tempVectorLayer = getMapLayer('tempLayer');
    const wmsTileLayer = getMapLayer('farmWms');

    const tempVectorSource = tempVectorLayer.getSource();
    if(tempVectorLayer) tempVectorLayer.setVisible(true);
    if(mainVectorLayer) mainVectorLayer.setVisible(false);
    if(wmsTileLayer) wmsTileLayer.setVisible(false);

    const select = new Select({
      layers: [tempVectorLayer],
      wrapX: false,
    });
    const modify = new Modify({
      source: tempVectorSource,
    });
    map.addInteraction(select);
    map.addInteraction(modify);
    setSelectInteraction(select);
    setModifyInteraction(modify);
  }

  // Function : Patch Api Call to set new geometry
  async function updateGeometry(newGeometry) {
    const sourceProjection = 'EPSG:3857';
    const targetProjection = 'EPSG:4326';
    const reprojectedGeometry = newGeometry.clone();
    reprojectedGeometry.transform(sourceProjection, targetProjection);
    setLoading(true);
    let wktFormat = new WKT({
      splitcollection: true,
    });
    let geometry = wktFormat.writeGeometry(reprojectedGeometry);
    let raw = JSON.stringify({
      geometry,
    });
    let requestOptions = {
      method: 'PATCH',
      headers: authHeader(),
      body: raw,
      redirect: 'follow',
    };
    await fetch(rootApiUrl + 'onefarm/' + params.farmId + '/', requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        toast.success('Geometry changed Successfully !');
      })
      .catch((error) => {
        console.log('error', error);
        toast.error('Error changing Geometry !');
      });
    setLoading(false);
  }

  // Function : Save Edited Map
  function saveEditedMap() {
    console.log('Edited !');
    map.removeInteraction(selectInteraction);
    map.removeInteraction(modifyInteraction);

    const tempVectorLayer = getMapLayer('tempLayer');
    const mainVectorLayer = getMapLayer('farmLayer');
    const wmsTileLayer = getMapLayer('farmWms');

    const mainVectorSource = mainVectorLayer.getSource();
    let feature = tempVectorLayer.getSource().getFeatures()[0];
    mainVectorSource.clear();
    mainVectorSource.addFeature(feature.clone());

    if(tempVectorLayer) tempVectorLayer.setVisible(false);
    if(mainVectorLayer) mainVectorLayer.setVisible(true);
    if(wmsTileLayer) wmsTileLayer.setVisible(true);
    setIsEditing(false);

    updateGeometry(feature.getGeometry());
  }

  // Function : Cancel Editing of Map
  function cancelEditingMap() {
    console.log('Cancelled');
    map.removeInteraction(selectInteraction);
    map.removeInteraction(modifyInteraction);

    const tempVectorLayer = getMapLayer('tempLayer');
    const mainVectorLayer = getMapLayer('farmLayer');
    const wmsTileLayer = getMapLayer('farmWms');

    const tempVectorSource = tempVectorLayer.getSource();
    let feature = mainVectorLayer.getSource().getFeatures()[0];
    tempVectorSource.clear();
    tempVectorSource.addFeature(feature.clone());

    if(tempVectorLayer) tempVectorLayer.setVisible(false);
    if(mainVectorLayer) mainVectorLayer.setVisible(true);
    if(wmsTileLayer) wmsTileLayer.setVisible(true);
    setIsEditing(false);
  }

  return (
    <Fragment>
      <AdviceModal
        show={showModal}
        cropId={params.farmId}
        adviceFor={measure}
        advisory={advisory}
        generatePDF={exportPdf}
        closeModal={() => {
          setShowModal(false);
        }}
      />
      <div className='flex flex-col-reverse h-screen lg:flex-row'>
        <div className='relative h-screen overflow-auto basis-7/12 lg:basis-5/12 bg-ext-dark text-ext-green-light'>
          <Link to={'/'}>
            <AppHeader />
          </Link>
          <ToastContainer
            position='top-center'
            closeOnClick
            pauseOnHover
            autoClose={2500}
          />
          <AppLoader show={loading} />
          <div className='bg-ext-darker'>
            <div className='col-span-5 p-6 bg-black'>
              <div className='pb-2 mb-3 text-4xl capitalize font-extralight text-ext-green'>
                {farmData?.properties.name}
              </div>

              <div className='flex flex-wrap justify-between'>
                <div className='pr-2'>
                  <div className='opacity-50'>Crops</div>
                  <div className='mb-3 text-2xl capitalize font-extralight'>
                    {farmData?.properties.crop}
                  </div>
                </div>
                <div className='pr-2'>
                  <div className='opacity-50'>Sowing Date</div>
                  <div className='mb-3 text-2xl capitalize font-extralight'>
                    {farmData?.properties.sowingdate}{' '}
                  </div>
                </div>
                <div className='pr-2'>
                  <div className='opacity-50'>Total Area</div>
                  <div className='mb-3 text-2xl font-extralight'>
                    {(farmData?.properties.area / 4046.85642).toFixed(3)}{' '}
                    <span className='text-base opacity-50'>Acers</span>
                  </div>
                </div>
              </div>

              {values[selectedValue]?.value !== null && (
                <button
                  className='w-full p-2 mt-3 text-white border rounded bg-ext-green border-ext-green'
                  onClick={() => {
                    getAdvisory(measure);
                  }}
                >
                  Advice
                </button>
              )}
            </div>
            <div className='col-span-7 p-6'>
              <div className='flex justify-between'>
                <select
                  className='text-2xl bg-transparent font-extralight'
                  value={measure}
                  onChange={changeMeasureOpt}
                >
                  <option value='ndvi' className='bg-black'>
                    NDVI
                  </option>
                  <option value='ndmi' className='bg-black'>
                    NDMI
                  </option>
                  <option value='evi' className='bg-black'>
                    EVI
                  </option>
                </select>
                <div className='relative inline-block'>
                  <button
                    type='button'
                    className='relative py-2 px-3 text-white border-0 rounded btn-secondary'
                    onClick={() => setIsOpen((prev) => !prev)}
                  >
                    Map Options
                    <span className='ml-2'>
                      <FontAwesomeIcon
                        icon={isOpen ? faCaretUp : faCaretDown}
                        className={`text-base ${
                          isOpen ? 'translate-y-0.5' : ''
                        }`}
                      />
                    </span>
                  </button>
                  {isOpen && (
                    <ul
                      className={`absolute top-full z-30 left-0 bg-ext-darker text-white border border-ext-dark border-t-0 ${
                        isOpen ? 'block' : 'hidden'
                      }`}
                    >
                      <li className='border-b'>
                        <Link
                          to={'/farms-comparison/' + params.farmId}
                          onClick={() => setIsOpen((prev) => !prev)}
                        >
                          <button className='bg-black text-white border-none text-left px-5 py-2 hover:bg-zinc-900'>
                            Compare Map
                          </button>
                        </Link>
                      </li>
                      <li>
                        <button
                          className={`bg-black text-white border-none text-left px-5 py-2 hover:bg-zinc-900 ${
                            isEditing
                              ? 'cursor-not-allowed pointer-events-none bg-zinc-900'
                              : ''
                          }`}
                          onClick={() => {
                            setIsOpen((prev) => !prev);
                            startEditingMap();
                          }}
                        >
                          Edit Geometry
                        </button>
                      </li>
                    </ul>
                  )}
                </div>
                {(() => {
                  if (values.length > 0) {
                    return (
                      <div className='opacity-50 my-auto'>
                        {values[selectedValue]?.date}
                      </div>
                    );
                  }

                  return null;
                })()}
              </div>
              {/* <div className="grid hidden grid-cols-3 gap-3">
                <div className="text-right">
                  <div>Max</div>
                  <div className="my-3 text-4xl font-extralight text-ext-green">
                    0.04
                  </div>

                  <div>Min</div>
                  <div className="my-3 text-4xl font-extralight text-ext-green">
                    -0.01
                  </div>
                </div>
                <div className="text-right">
                  <div>Mean</div>
                  <div className="my-3 text-4xl font-extralight text-ext-green">
                    0.01
                  </div>

                  <div>Deviation</div>
                  <div className="my-3 text-4xl font-extralight text-ext-green">
                    0.01
                  </div>
                </div>
                <div className="text-right">
                  <div>Median</div>
                  <div className="my-3 text-4xl font-extralight text-ext-green">
                    0.01
                  </div>

                  <div>Num</div>
                  <div className="my-3 text-4xl font-extralight text-ext-green">
                    40393
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <div className='flex items-center p-6'>
            <div className='pr-6 text-center'>
              <FontAwesomeIcon icon={faCalendarDays} className='text-2xl' />
              <br></br>
              Date
            </div>
            <div className='pb-2 overflow-y-auto'>
              <div className='flex w-max'>
                {values?.map(
                  (value, index) =>
                    value.value && (
                      <button
                        key={value.id}
                        className={
                          selectedValue === index
                            ? 'bg-ext-green bg-opacity-30 rounded-md'
                            : ''
                        }
                        type='submit'
                        onClick={() => {
                          setSelectedValue(parseInt(index));
                        }}
                      >
                        <DataOnDate
                          date={value.date}
                          // cloud="100%"
                          // image="100%"
                          sat={value.value.toFixed(8)}
                        />
                      </button>
                    )
                )}
              </div>
            </div>
          </div>

          <div className='py-3 bg-ext-darker'>
            <div className='flex items-center justify-between px-6 xl:block 2xl:flex'>
              <div>
                <div className='opacity-50'>Historical</div>
                <div className='mb-3 text-4xl uppercase font-extralight'>
                  {measure}
                </div>
              </div>
              <div className='flex flex-col xl:flex-row'>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    let year = date.getFullYear();
                    let month = date.getMonth() + 1;
                    let nth = date.getDate();
                    setStartDate(
                      new Date(year + '/' + month + '/' + nth + ' 0:0:0')
                    );
                  }}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={today}
                  excludeDates={dateExclude}
                  className='p-2 bg-black rounded-md xl:mr-2'
                  placeholderText='From date'
                />
                <DatePicker
                  selected={endDate}
                  onChange={(date) => {
                    let year = date.getFullYear();
                    let month = date.getMonth() + 1;
                    let nth = date.getDate();
                    setEndDate(
                      new Date(year + '/' + month + '/' + nth + ' 23:59:59')
                    );
                  }}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  maxDate={today}
                  excludeDates={dateExclude}
                  className='p-2 mt-2 bg-black rounded-md xl:mt-0'
                />
                <button
                  className='mt-2 btn-secondary xl:ml-2 xl:mt-0'
                  onClick={filterByDate}
                >
                  Update
                </button>
              </div>
            </div>
            <DataChart data={chartData} height='300px' />
          </div>
        </div>
        <div className='relative basis-5/12 lg:basis-7/12'>
          {isEditing ? (
            <div className='absolute bottom-4 right-2 z-10 flex items-center justify-center gap-2'>
              <button
                className='py-2 px-3 text-white border-0 rounded shadow bg-ext-green'
                onClick={saveEditedMap}
              >
                Save
              </button>
              <button
                className='py-2 px-3 text-white border-0 rounded bg-red-700'
                onClick={cancelEditingMap}
              >
                Cancel
              </button>
            </div>
          ) : null}
          <span className='absolute z-10 p-3 bg-white rounded-lg shadow-lg bottom-4 left-4'>
            {!showLegend && (
              <span
                className='text-xs font-bold cursor-pointer text-ext-darker'
                onClick={() => {
                  setShowLegend(!showLegend);
                }}
              >
                <FontAwesomeIcon icon={faEllipsisVertical} className='mr-2' />{' '}
                Show Legend
              </span>
            )}
            {showLegend && (
              <FontAwesomeIcon
                icon={faRectangleXmark}
                className='absolute z-10 block text-2xl text-red-500 cursor-pointer -right-1 -top-1'
                onClick={() => {
                  setShowLegend(!showLegend);
                }}
              />
            )}
            {measure === 'ndvi' && showLegend && (
              <img src={ndviImg} alt='legend' className='h-[35vh]' />
            )}
            {measure === 'ndmi' && showLegend && (
              <img src={ndmiImg} alt='legend' className='h-[35vh]' />
            )}
            {measure === 'evi' && showLegend && (
              <img src={eviImg} alt='legend' className='h-[35vh]' />
            )}
          </span>
          <MyMap center={center} zoom={5} trackLocation={false}>
            <img
              src={imgNorth}
              alt='north'
              className='absolute z-10 object-contain p-1 rounded-md w-9 h-9 bg-ext-green-light bg-opacity-60 right-2 top-2'
            />
            <Popup type='wms' edit={isEditing} />
            <MyLayers>
              <TileLayer
                source={xyz(
                  'http://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'
                )}
                zIndex={0}
              />
              {values.length > 0 && (
                <TileLayer
                  name='farmWms'
                  source={wms({
                    url: 'https://gis.khetibuddy.com/geoserver/kb/wms',
                    layer: layerName,
                  })}
                  zIndex={2}
                />
              )}
              {farmDataOL && (
                <VectorLayer
                  name='tempLayer'
                  color='#3399CC'
                  visible={false}
                  zIndex={3}
                  source={vector({
                    features: [farmDataOL.clone()],
                  })}
                />
              )}
              {farmDataOL && (
                <VectorLayer
                  name='farmLayer'
                  zIndex={1}
                  source={vector({
                    features: [farmDataOL],
                  })}
                />
              )}
            </MyLayers>
          </MyMap>
        </div>
      </div>
    </Fragment>
  );
}

export default FarmPage;
