import { useContext, useEffect } from "react";
// import MapContext from "../Map/MapContext";
import { useSelector } from "react-redux";
import OLVectorLayer from "ol/layer/Vector";
// import {getCenter, createEmpty, extend} from 'ol/extent'
import { Fill, Stroke, Style} from 'ol/style';

const VectorLayer = ({ name = "", color = "blue", visible = true, source, zIndex = 0, isMap2 = false, isComparison = false }) => {
	// const { map } = useContext(MapContext);
	const map = useSelector((state) => isComparison ? isMap2 ? state.gis.icMap2 : state.gis.icMap1 : state.gis.map);
	
	useEffect(() => {
		if (!map) return;
		let vectorLayer = new OLVectorLayer({
			name,
			source,
			visible,
			style: new Style({
				stroke: new Stroke({
				  color,
				  width: 3,
				}),
				fill: new Fill({
				  color: 'rgba(0, 0, 255, 0)',
				}),
			  })
		});
		map.addLayer(vectorLayer);
		vectorLayer.setZIndex(zIndex);

		if(source.getFeatures().length > 0) {
			setTimeout(
				function() {
					map.getView().fit(source.getExtent(), map.getSize())
				},
				1000
			);
		}

		return () => {
			if (map) {
				map.removeLayer(vectorLayer);
			}
		};
	}, [map]);

	return null;
};

export default VectorLayer;