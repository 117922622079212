import React from "react";

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <div className="relative flex w-full items-center px-3 pt-3">
    <input
        className="form-control"
      id="search"
      type="text"
      placeholder="Filter table data..."
      value={filterText}
      onChange={onFilter}
    />
    <button onClick={onClear} className="btn-primary min-w-max ml-1 ">Clear Filter</button>
  </div>
);

export default FilterComponent;
