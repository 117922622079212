import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarDays,
  faEllipsisVertical,
  faRectangleXmark,
} from '@fortawesome/free-solid-svg-icons';
import imgNorth from '../../images/north.png';
import Popup from '../Mapping/components/Popup/Popup';
import { MyLayers, VectorLayer, TileLayer } from '../Mapping/components/Layers';
import { vector, wms, xyz } from '../Mapping/components/Source';
import { useSelector } from 'react-redux';
import authHeader from '../../services/auth-header';
import { get } from 'ol/proj';
import GeoJSON from 'ol/format/GeoJSON';
import DataOnDate from '../farmPage/DataOnDate';
import AppLoader from '../ui/AppLoader';
import ComparisonMaps from '../Mapping/components/Map/ComparisonMaps';
import eviImg from '../../images/evi.jpeg';
import ndviImg from '../../images/ndvi.jpeg';
import ndmiImg from '../../images/ndmi.jpeg';

const Map2Component = ({ params }) => {
  const navigate = useNavigate();
  const { rootApiUrl, isLogedIn } = useSelector((state) => state.global);
  const [center, setCenter] = useState([78.9629, 20.5937]);
  const [isEditing, setIsEditing] = useState(false);
  const [farmData, setFarmData] = useState(null);
  const [farmDataOL, setFarmDataOL] = useState(null);
  const [values, setValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [measure, setMeasure] = useState('ndvi');
  const [selectedValue, setSelectedValue] = useState(0);
  const [showLegend, setShowLegend] = useState(false);

  useEffect(() => {
    if (!isLogedIn) return;
    async function getFarmData() {
      setLoading(true);

      const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        redirect: 'follow',
      };

      await fetch(rootApiUrl + 'onefarm/' + params.farmId + '/', requestOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            navigate('/');
          }
        })
        .then((data) => {
          setFarmData(data);
          setCenter([data.properties.lon, data.properties.lat]);
          setFarmDataOL(
            new GeoJSON().readFeature(data, {
              featureProjection: get('EPSG:3857'),
            })
          );
          if (!data.geometry) {
            navigate('/add-farm/' + data.id);
          }
        });

      setLoading(false);
    }

    getFarmData();
    getValues(measure);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function changeMeasureOpt(event) {
    setMeasure(event.target.value);
    getValues(event.target.value);
  }

  async function getValues(opt) {
    setLoading(true);

    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
    };

    await fetch(rootApiUrl + opt + '/?farmid=' + params.farmId, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setValues(data);
      });
    setLoading(false);
  }

  let layerName =
    values.length > 0 &&
    'kb:' + measure + '-' + values[selectedValue]?.date + '-' + params.farmId;

  return (
    <>
      <AppLoader show={loading} />
      <div className='flex flex-col w-full lg:w-1/2 lg:border-l-2 lg:border-lime-700 bg-ext-dark text-ext-green-light'>
        <div className='relative h-[50vh] lg:h-auto lg:basis-3/4 text-ext-dark'>
          <span className='absolute z-10 p-2 bg-white rounded-lg shadow-lg bottom-4 left-4'>
            {!showLegend && (
              <span
                className='text-xs font-bold cursor-pointer text-ext-darker'
                onClick={() => {
                  setShowLegend(!showLegend);
                }}
              >
                <FontAwesomeIcon icon={faEllipsisVertical} className='mr-2' />{' '}
                Show Legend
              </span>
            )}
            {showLegend && (
              <FontAwesomeIcon
                icon={faRectangleXmark}
                className='absolute z-10 block text-2xl text-red-500 cursor-pointer -right-1 -top-1'
                onClick={() => {
                  setShowLegend(!showLegend);
                }}
              />
            )}
            {measure === 'ndvi' && showLegend && (
              <img src={ndviImg} alt='legend' className='h-[35vh]' />
            )}
            {measure === 'ndmi' && showLegend && (
              <img src={ndmiImg} alt='legend' className='h-[35vh]' />
            )}
            {measure === 'evi' && showLegend && (
              <img src={eviImg} alt='legend' className='h-[35vh]' />
            )}
          </span>
          <ComparisonMaps
            center={center}
            zoom={5}
            trackLocation={false}
            isMap2={true}
          >
            <img
              src={imgNorth}
              alt='north'
              className='absolute z-10 object-contain p-1 rounded-md w-9 h-9 bg-ext-green-light bg-opacity-60 right-2 top-2'
            />
            <Popup
              type='wms'
              edit={isEditing}
              isComparison={true}
              isMap2={true}
            />
            <MyLayers>
              <TileLayer
                source={xyz(
                  'http://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'
                )}
                isMap2={true}
                isComparison={true}
                zIndex={0}
              />
              {values.length > 0 && (
                <TileLayer
                  name='farmWms'
                  source={wms({
                    url: 'https://gis.khetibuddy.com/geoserver/kb/wms',
                    layer: layerName,
                  })}
                  isMap2={true}
                  isComparison={true}
                  zIndex={2}
                />
              )}
              {farmDataOL && (
                <VectorLayer
                  name='farmLayer'
                  zIndex={1}
                  isMap2={true}
                  isComparison={true}
                  source={vector({
                    features: [farmDataOL],
                  })}
                />
              )}
            </MyLayers>
          </ComparisonMaps>
        </div>
        <div className='h-[20vh] lg:h-auto lg:basis-1/4 p-2 bg-ext-dark'>
          <div className='flex justify-between '>
            <select
              className='text-2xl bg-transparent font-extralight'
              value={measure}
              onChange={changeMeasureOpt}
            >
              <option value='ndvi' className='bg-black'>
                NDVI
              </option>
              <option value='ndmi' className='bg-black'>
                NDMI
              </option>
              <option value='evi' className='bg-black'>
                EVI
              </option>
            </select>
            {(() => {
              if (values.length > 0) {
                return (
                  <div className='mt-1 opacity-50'>
                    {values[selectedValue]?.date}
                  </div>
                );
              }
              return null;
            })()}
          </div>
          <div className='flex items-center mt-3'>
            <div className='mr-3 text-center'>
              <FontAwesomeIcon icon={faCalendarDays} className='text-2xl' />
              <br></br>
              Date
            </div>
            <div className=' overflow-y-auto pb-1'>
              <div className='flex w-max'>
                {values?.map((value, index) =>
                  value.value ? (
                    <button
                      key={value.id}
                      className={
                        selectedValue === index
                          ? 'bg-ext-green bg-opacity-30 rounded-md'
                          : ''
                      }
                      type='submit'
                      onClick={() => {
                        setSelectedValue(parseInt(index));
                      }}
                    >
                      <DataOnDate
                        date={value.date}
                        // cloud="100%"
                        // image="100%"
                        sat={value.value.toFixed(8)}
                      />
                    </button>
                  ) : (
                    <ul
                      key={index}
                      className='p-3 text-left border-r rounded-lg border-ext-darker'
                    >
                      <li className='mb-2'>
                        <FontAwesomeIcon
                          icon={faCalendarDays}
                          className='w-6 mr-2 text-lg text-ext-green'
                        />{' '}
                        {value.date}
                      </li>
                      <li className='opacity-50'>Data not available</li>
                    </ul>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Map2Component;
