import { useContext, useEffect } from "react";
// import MapContext from "../Map/MapContext";
import { useSelector } from "react-redux";
import OLTileLayer from "ol/layer/Tile";

const TileLayer = ({ source, zIndex = 0, name = "", isMap2 = false, isComparison = false }) => {
	// const { map } = useContext(MapContext);
	const map = useSelector((state) => isComparison ? isMap2 ? state.gis.icMap2 : state.gis.icMap1 : state.gis.map);

	useEffect(() => {
		if (!map) return;
		let tileLayer = new OLTileLayer({
			source,
			zIndex,
		});

		map.addLayer(tileLayer);
		if(name !== "")
			tileLayer.set("name", name);
		tileLayer.setZIndex(zIndex);

		return () => {
			if (map) {
				map.removeLayer(tileLayer);
			}
		};
	}, [map, source.key_]);

	return null;
};

export default TileLayer;