import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    map: null,
    icMap1:null,
    icMap2: null,
    draw: null,
    area: null,
    geometryWKT: null,
}

const gisSlice = createSlice({
    name: 'gis',
    initialState,
    reducers: {
        updateMap(state, action) {
            state.map = action.payload
        },
        updateICMap1(state, action) {
            state.icMap1 = action.payload
        },
        updateICMap2(state, action) {
            state.icMap2 = action.payload
        },
        updateDraw(state, action) {
            state.draw = action.payload
        },
        updateArea(state, action) {
            state.area = action.payload
        },
        updateGeometryWKT(state, action) {
            state.geometryWKT = action.payload
        }
    }
})

export const gisAction = gisSlice.actions;

export default gisSlice.reducer;