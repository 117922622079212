import React from 'react'
import AppHeader from '../components/AppHeader'

function Index() {
  return (
    <div className='bg-ext-dark text-ext-green-light h-screen'>
        <AppHeader />
        <div className="grid grid-cols-12 absolute inset-x-0 top-20 bottom-0">
            <div className="col-span-7  overflow-auto relative p-5">
                <input type='text' className='form-control p-6' placeholder='Search' />

                <div>
                    <ul>
                        <li className='flex justify-between items-center bg-ext-green bg-opacity-30 p-4 rounded-md shadow-md border border-ext-green-light border-opacity-25 mb-3'>
                            <div>
                                <span className='text-sm opacity-50'>#ID000321654</span><br />
                                <span className='text-2xl font-light'>Farmer Name</span>
                            </div>
                            <div className='font-light text-xl'>
                                803.70ha
                            </div>
                        </li>
                        <li className='flex justify-between items-center bg-ext-green bg-opacity-30 p-4 rounded-md shadow-md border border-ext-green-light border-opacity-25 mb-3'>
                            <div>
                                <span className='text-sm opacity-50'>#ID000321654</span><br />
                                <span className='text-2xl font-light'>Farmer Name</span>
                            </div>
                            <div className='font-light text-xl'>
                                803.70ha
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='col-span-5'></div>
        </div>
    </div>
  )
}

export default Index