import { Routes, Route } from "react-router-dom";
import './App.css';
import AddFarm from './pages/AddFarm';
import Index from './pages/Index';
import Dashboard from './pages/Dashboard';
import FarmPage from './pages/FarmPage';
import Page404 from "./pages/Page404";
import LoginPage from "./pages/LoginPage";
import FarmsMobile from "./pages/FarmsMobile";
import ImageComparison from "./pages/ImageComparison";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="index" element={<Index />} />
        <Route path="add-farm/:farmId" element={<AddFarm />} />
        <Route path="farms/:farmId" element={<FarmPage />} />
        <Route path="farms-comparison/:farmId" element={<ImageComparison />} />
        <Route path="farmsmobile/" element={<FarmsMobile />} />
        <Route path="/*" element={<Page404 />} />
      </Routes>
    </div>
  );
}

export default App;
