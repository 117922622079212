export default function authHeader() {
  const token = localStorage.getItem("token");

  if (token) {
    return {
      Authorization: "Token " + token,
      "Content-Type": "application/json",
    };
  } else {
    return {};
  }
}
