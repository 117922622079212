import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  MyLayers,
  TileLayer,
  VectorLayer,
} from '../components/Mapping/components/Layers';
import MyMap from '../components/Mapping/components/Map/MyMap';
import Popup from '../components/Mapping/components/Popup/Popup';
import { osm, vector, wms, xyz } from '../components/Mapping/components/Source';
import imgNorth from '../images/north.png';
import eviImg from '../images/evi.jpeg';
import ndviImg from '../images/ndvi.jpeg';
import ndmiImg from '../images/ndmi.jpeg';
import authHeader from '../services/auth-header';
import { fromLonLat, get } from 'ol/proj';
import GeoJSON from 'ol/format/GeoJSON';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisVertical,
  faRectangleXmark,
} from '@fortawesome/free-solid-svg-icons';

function FarmsMobile() {
  const { rootApiUrl, isLogedIn } = useSelector((state) => state.global);

  let params = new URL(document.location).searchParams;
  const [farm, srtFarm] = useState();
  const [date, srtDate] = useState();
  const [center, setCenter] = useState([78.9629, 20.5937]);
  const [layername, setlayername] = useState('');

  const [farmDataOL, setFarmDataOL] = useState(null);
  const [values, setvalue] = useState(0);
  const [measure, setMeasure] = useState('ndvi');
  const [showLegend, setShowLegend] = useState(false);

  useEffect(() => {
    srtFarm(params.get('farm'));
    srtDate(params.get('date'));
    setMeasure(params.get('index'));
    setvalue(1);
    async function getFarmData() {
      // setLoading( true );

      const requestOptions = {
        method: 'GET',
        headers: authHeader(),
        redirect: 'follow',
      };

      await fetch(
        rootApiUrl + 'farmdata_mobile/' + params.get('farm'),
        requestOptions
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          setCenter([
            data.features[0].properties.lon,
            data.features[0].properties.lat,
          ]);
          setFarmDataOL(
            new GeoJSON().readFeature(data.features[0], {
              featureProjection: get('EPSG:3857'),
            })
          );
        });
    }

    getFarmData();
  }, []);

  const layName =
    'kb:' +
    params.get('index') +
    '-' +
    params.get('date') +
    '-' +
    params.get('farm');

  return (
    <div className='fixed inset-0'>
      <span className='absolute z-10 p-3 bg-white rounded-lg shadow-lg bottom-4 left-4'>
        {!showLegend && (
          <span
            className='text-xs font-bold cursor-pointer text-ext-darker'
            onClick={() => {
              setShowLegend(!showLegend);
            }}
          >
            <FontAwesomeIcon icon={faEllipsisVertical} className='mr-2' /> Show
            Legend
          </span>
        )}
        {showLegend && (
          <FontAwesomeIcon
            icon={faRectangleXmark}
            className='absolute z-10 block text-2xl text-red-500 cursor-pointer -right-1 -top-1'
            onClick={() => {
              setShowLegend(!showLegend);
            }}
          />
        )}
        {measure === 'ndvi' && showLegend && (
          <img src={ndviImg} alt='legend' className='h-[35vh]' />
        )}
        {measure === 'ndmi' && showLegend && (
          <img src={ndmiImg} alt='legend' className='h-[35vh]' />
        )}
        {measure === 'evi' && showLegend && (
          <img src={eviImg} alt='legend' className='h-[35vh]' />
        )}
      </span>
      <MyMap center={center} zoom={5} trackLocation={false}>
        <img
          src={imgNorth}
          alt='north'
          className='absolute z-10 object-contain p-1 rounded-md w-9 h-9 bg-ext-green-light bg-opacity-60 right-2 top-2'
        />
        <Popup type='wms' />
        <MyLayers>
          <TileLayer
            // source={osm()}
            source={xyz('http://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}')}
            zIndex={0}
          />
          {
            <TileLayer
              name='farmWms'
              source={wms({
                url: 'https://gis.khetibuddy.com/geoserver/kb/wms',
                layer: layName,
              })}
              zIndex={1}
            />
          }
          {farmDataOL && (
            <VectorLayer
              zIndex='1'
              source={vector({
                features: [farmDataOL],
              })}
            />
          )}
        </MyLayers>
      </MyMap>
    </div>
  );
}

export default FarmsMobile;
